import React from "react";
import "./Footer.css"; // Import the footer.css file
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTiktok,
  faYoutube,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <footer>
      <div className="box">
        <div className="container">
          <div className="row">
            <div className="column">
              <h2 className="heading">About </h2>
              <a href="/about" className="footer-link">
                Our Story
              </a>
              <a href="/faq" className="footer-link">
                FAQ
              </a>
              <a href="/blog" className="footer-link">
                Blog
              </a>
            </div>
            <div className="column">
              <h2 className="heading">Strategies</h2>
              <a href="/strategies" className="footer-link">
                TradeOrFade
              </a>
              <a href="/strategies" className="footer-link">
                TrendHunter
              </a>
              {/* <a href="/strategies" className="footer-link">
                BreakoutLong
              </a> */}
              <a href="/strategies" className="footer-link">
                CrudeConqueror
              </a>
              {/* <a href="/strategies" className="footer-link">
                KCSniper
              </a> */}
              <a href="/strategies" className="footer-link">
                GoldDigger
              </a>
            </div>
            <div className="column">
              <h2 className="heading">Contact Us</h2>
              <a href="/contact" className="footer-link">
                Send us a message
              </a>
              <a href="/affiliates" className="footer-link">
                Become An Affiliate
              </a>
              <a
                href="https://calendly.com/jjbyfield/30min"
                className="footer-link"
                target="_blank"
              >
                Book A Demo
              </a>
            </div>
            <div className="column">
              <h2 className="heading">Trading Platform</h2>
              <a href="/platform" className="footer-link">
                NinjaTrader
              </a>
            </div>
            <div className="column">
              <h2 className="heading">Social Media</h2>
              <a
                href="https://www.tiktok.com/@ziontradingalgos"
                className="footer-link"
              >
                <FontAwesomeIcon icon={faTiktok} color="white" />
                <span className="social-media-link">TikTok</span>
              </a>
              <a
                href="https://www.instagram.com/ziontradingalgos"
                className="footer-link"
              >
                <FontAwesomeIcon icon={faInstagram} color="white" />
                <span className="social-media-link">Instagram</span>
              </a>
              <a href="https://twitter.com/zionalgos" className="footer-link">
                <FontAwesomeIcon icon={faTwitter} color="white" />
                <span className="social-media-link">Twitter</span>
              </a>
              <a
                href="https://www.youtube.com/@ZionTradingAlgos"
                className="footer-link"
              >
                <FontAwesomeIcon icon={faYoutube} color="white" />
                <span className="social-media-link">Youtube</span>
              </a>
            </div>

            <div className="column">
              <h2 className="heading">Disclaimers</h2>
              <a href="/privacy" className="footer-link">
                Privacy Policy
              </a>
              <a href="/risk" className="footer-link">
                Risk Disclosure
              </a>
              <a href="/return" className="footer-link">
                Return Policy
              </a>
            </div>
          </div>
          <div className="copyright">
            &copy; 2024 Zion Trading Algos. All rights reserved.
            <div className="cftc-rule">
              <p>
                CFTC Rule 4.41 - Hypothetical or simulated performance results
                have certain limitations. Unlike an actual performance record,
                simulated results do not represent actual trading. Also, since
                the trades have not been executed, the results may have
                under-or-over compensated for the impact, if any, of certain
                market factors, such as lack of liquidity. Simulated trading
                programs in general are also subject to the fact that they are
                designed with the benefit of hindsight. No representation is
                being made that any account will or is likely to achieve profit
                or losses similar to those shown.
              </p>
              <p>
                Futures and forex trading contains substantial risk and is not
                for every investor. An investor could potentially lose all or
                more than the initial investment. Risk capital is money that can
                be lost without jeopardizing ones’ financial security or life
                style. Only risk capital should be used for trading and only
                those with sufficient risk capital should consider trading. Past
                performance is not necessarily indicative of future results.
              </p>
              <p>
                Important Notice: The testimonials presented on our website from
                our customers are their personal opinions and experiences with
                our algorithmic trading bots. They are individual results and
                vary from person to person. These testimonials are not intended
                to make promises of outcomes but to share experiences.
              </p>
              <p>
                No Guarantee of Results or Performance: The testimonials on this
                site are not to be interpreted as a promise or guarantee of
                similar success or returns. The trading performance you
                experience will depend on a variety of factors, including but
                not limited to market conditions, your risk tolerance, and
                trading discipline.
              </p>
              <p>
                Not Financial Advice: The testimonials are provided for
                informational purposes only and should not be construed as
                investment advice. We do not claim that the use of our
                algorithmic trading bots will result in profitable trading or be
                free of risk of loss.
              </p>
              <p>
                We encourage all potential customers to conduct their own due
                diligence and consult with a qualified financial professional
                before making any investment decisions.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
