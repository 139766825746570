import React, {useEffect, useState} from 'react';
import backtestImage from "../images/trade_performance/tradeorfade_backtest_summary.jpg";
import equityCurveImage from "../images/trade_performance/tradeorfade_equity.jpg";
import monteCarloImage1 from "../images/EquitySurferMC.png";
import portfolioCorrelation from "../images/portfolio_analyzer/quant_1_portfolio_correlation.png";

import equitySurferMC from "../images/EquitySurferMC.png";
import blueFlameMC from "../images/BlueFlameMC.png";
import crudeConquerorMC from "../images/CrudeConquerorMC.png";
import nasdaqNavigatorMC from "../images/NasdaqNavigatorMC.png";
import goldDiggerMC from "../images/GoldDiggerMC.png";
import candleScalperMC from "../images/CandleScalperMC.png";
import equitySurferBT from "../images/trade_performance/EquitySurferBacktest.png";
import equitySurferEQ from "../images/trade_performance/EquitySurferEquity.png";

import nasdaqNavigatorBT from "../images/trade_performance/NasdaqNavigatorBacktest.png";
import nasdaqNavigatorEQ from "../images/trade_performance/NasdaqNavigatorEquity.png";

import goldDiggerBT from "../images/trade_performance/GoldDiggerBacktest.png";
import goldDiggerEQ from "../images/trade_performance/GoldDiggerEquity.png";

import candleScalperBT from "../images/trade_performance/CandleScalperBacktest.png";
import candleScalperEQ from "../images/trade_performance/CandleScalperEquity.png";

import crudeConquerorBT from "../images/trade_performance/CrudeConquerorBacktest.png";
import crudeConquerorEQ from "../images/trade_performance/CrudeConquerorEquity.png";

import blueFlameBT from "../images/trade_performance/BlueFlameBacktest.png";
import blueFlameEQ from "../images/trade_performance/BlueFlameEquity.png";
import Image from "react-image-enlarger";

const performanceTypes = ["ES 10 Min", "NQ 60 Min", "GC 20 Min", "CL 40 Min", "NQ 30 Min", "NG 25 Min"];
const performanceImages = {
  "ES 10 Min": [
    { src: equitySurferBT, alt: "Equity Surfer Backtest", description: "Backtest 2010-2024", name: "equitySurferBT" },
    { src: equitySurferEQ, alt: "Equity Surfer Equity Curve", description: "Equity Curve 2008-2024", name: "equitySurferEQ" },
    { src: equitySurferMC, alt: "Equity Surfer Monte Carlo", description: "Monte Carlo 2008-2024", name: "equitySurferMC" }
  ],
  "NQ 60 Min": [
    { src: nasdaqNavigatorBT, alt: "Nasdaq Navigator Backtest", description: "Backtest 2008-2024", name: "nasdaqNavigatorBT" },
    { src: nasdaqNavigatorEQ, alt: "Nasdaq Navigator Equity Curve", description: "Equity Curve 2008-2024", name: "nasdaqNavigatorEQ" },
      { src: nasdaqNavigatorMC, alt:"Nasdaq Navigator Monte Carlo", description: "Monte Carlo 2008-2024", name: "nasdaqNavigatorMC"}
  ],
  "GC 20 Min": [
    { src: goldDiggerBT, alt: "Gold Digger Backtest", description: "Backtest 2008-2024", name: "goldDiggerBT" },
    { src: goldDiggerEQ, alt: "Gold Digger Equity Curve", description: "Equity Curve 2008-2024", name: "goldDiggerEQ" },
       { src: goldDiggerMC, alt:"Gold Digger Monte Carlo", description: "Monte Carlo 2008-2024", name: "goldDiggerMC"}

  ],
  "CL 40 Min": [
    { src: crudeConquerorBT, alt: "Crude Conqueror Backtest", description: "Backtest 2008-2024", name: "crudeConquerorBT" },
    { src: crudeConquerorEQ, alt: "Crude Conqueror Equity Curve", description: "Equity Curve 2008-2024", name: "crudeConquerorEQ" },
      { src: crudeConquerorMC, alt:"Crude Conqueror Monte Carlo", description: "Monte Carlo 2008-2024", name: "crudeConquerorMC"}
  ],
  "NQ 30 Min": [
    { src: candleScalperBT, alt: "Candle Scalper Backtest", description: "Backtest 2018-2024", name: "candleScalperBT" },
    { src: candleScalperEQ, alt: "Candle Scalper Equity Curve", description: "Equity Curve 2018-2024", name: "candleScalperEQ" },
      { src: candleScalperMC, alt:"Candle Scalper Monte Carlo", description: "Monte Carlo 2018-2024", name: "candleScalperMC"}
  ],
  "NG 25 Min": [
    { src: blueFlameBT, alt: "Blue Flame Backtest", description: "Backtest 2008-2024", name: "blueFlameBT" },
    { src: blueFlameEQ, alt: "Blue Flame Equity Curve", description: "Equity Curve 2008-2024", name: "blueFlameEQ" },
      { src: blueFlameMC, alt:"Blue Flame Monte Carlo", description: "Monte Carlo 2008-2024", name: "blueFlameMC"}
  ],
};
// const performanceImages = [
//   {
//       src: equitySurferBT,
//       alt: "Backtest 2008-2024 bt",
//       description: "Backtest 2008-2024",
//       name: "equitySurferBT"
//         },
//   {
//       src: equitySurferEQ,
//       alt: "Backtest 2008-2024 eq",
//       description: "Equity Curve 2008-2024",
//       name: "equitySurferEQ"},
//   {
//       src: equitySurferMC,
//       alt: "Backtest 2008-2024 mt",
//       description: "Monte Carlo 2008-2024",
//       name: "equitySurferMC"
//   },
// ];

function HistoricalPerformance() {
    useEffect(() => {}, []);
    const [zoomedStates, setZoomedStates] = useState({});
    const [selectedType, setSelectedType] = useState(performanceTypes[0]);
    const [isFading, setIsFading] = useState(false);

  const handleImageClick = (imageName) => {

    setZoomedStates({
      ...zoomedStates,
      [imageName]: !zoomedStates[imageName],
    });
  };

  const handleChangeType = (type) => {
    setIsFading(true);
    setTimeout(() => {
      setSelectedType(type);
      setIsFading(false);
    }, 300); // Duration of fade-out
  };

  const renderZoomableImage = (src, alt, imageName, className) => (
    <Image
      className={className}
      src={src}
      overlayColor="rgba(255,255,255,0.2)"
      zoomed={zoomedStates[imageName]}
      alt={alt}
      onClick={() => handleImageClick(imageName)}
      onRequestClose={() => handleImageClick(imageName)}
    />
  );

  function PerformanceType({ type }) {
  return (
      <div className="mb-2 mr-1">
          <button
              className={`w-40 bg-gradient-to-b from-[#18181a] to-[#18181a] border-2 border-[#3b354b] rounded-full ${selectedType === type ? 'opacity-100' : 'opacity-60'}`}
              onClick={() => handleChangeType(type)}
          >
              {type}
          </button>
      </div>
  );
  }

    function PerformanceImage({src, alt, desc, name}) {
        return (
            <div className="flex flex-col w-full md:w-[33%] max-md:ml-0 max-md:w-full">
                <div
                    className="flex relative flex-col grow px-5 md:pb-5 text-3xl tracking-tighter
                    text-slate-300 max-md:pt-12 max-md:mt-7
                    ">
                    {renderZoomableImage(
                        src,
                        alt,
                        name,
                        "object-cover inset-0 size-full rounded-2xl aspect-[1.431] border border-slate-200/40"
                    )}
                    <p className="text-center text-2xl md:text-3xl z-10 relative">{desc}</p>
                </div>
            </div>
        );
    }

    return (
        <section className="flex flex-col text-center items-center mt-20 max-w-[1349px] max-md:mt-10 max-md:max-w-full">
          <h2 className="flex flex-wrap gap-4 max-w-full text-3xl md:text-6xl font-bold leading-none text-center rounded-none ">
            <p className="tracking-tighter text-slate-300">Historical</p>
            <p className="text-transparent tracking-wider bg-clip-text bg-[linear-gradient(90deg,#569DB7_0%,#C2D1FB_100%)] max-md:max-w-full">Performance</p>
          </h2>
          <div className="flex flex-col text-center bg-[#202026] px-12 pt-9 md:pb-16 md:mt-14 w-full md:h-[482px] rounded-3xl
                          max-md:px-5 max-md:mt-10 max-md:max-w-full shadow-[0_4px_132px_0px_#010103] pb-4">
              <div
                  className="md:flex justify-between md:gap-3.5 self-start text-xl tracking-tight text-white
                  grid grid-cols-2 gap-4 mx-auto md:mx-0 md:grid-cols-1">
                  {performanceTypes.map((type, index) => (
                      <PerformanceType key={index} type={type}/>
                  ))}
              </div>
              <div
                  className={`-mt-10 md:mt-10 max-md:max-w-full transition-opacity duration-300 ${isFading ? 'opacity-0' : 'opacity-100'}`}>
                  <div className="flex md:gap-5 max-md:flex-col">
                      {performanceImages[selectedType].map((image, index) => (
              <PerformanceImage key={index} src={image.src} alt={image.alt} desc={image.description} name={image.name}/>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default HistoricalPerformance;