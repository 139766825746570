import React from 'react';
import './StrategyItem.css'
import CTAButton from './CTAButton';
import './CTAButton.css';
import { Link } from 'react-router-dom';


const StrategyItem = ({ imageSrc, title, description }) => {
  return (
    <div className="strategy-info-block">
      <img src={imageSrc} alt="Image" className="strategy-info-block__img" loading="lazy"/>
        <div className="strategy-info-block__content">
            <h2 className="strategy-info-block__title">{title}</h2>
            <p className="strategy-info-block__description">
                {description}
                <button className='ui-btn mx-start mt-[56px]'>
                    <Link to={`/strategies/${title}`}>
                        <span className="font-semibold w-full">Get This Algo</span>
                    </Link>
                </button>
            </p>
            {/*<Link to={`/strategies/${title}`} style={{textDecoration: 'none'}}>*/}
            {/*    <CTAButton title="Get This Algo"/>*/}
            {/*</Link>*/}

        </div>
    </div>
  );
};

export default StrategyItem;
