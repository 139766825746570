import React, { useState } from "react";
import HeroTitle from "./HeroTitle";
import CTAButton from "./CTAButton";
import backtestImage from "../images/trade_performance/trendhunter_backtest.jpg";
import equityCurveImage from "../images/trade_performance/trendhunter_equity.jpg";
import monteCarloImage from "../images/trade_performance/trendhunter_montecarlo.jpg";
import strategyImage from "../images/trade_performance/demo_tradeorfade_compressed.gif";
import "./StrategyDetails.css";
import Counter from "./Counter";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import Image from "react-image-enlarger";
import returnsImage from "../images/th_monthly_returns.png";
import MonthlyReturnsTable from "./MonthlyReturnsTable";
import algoTrial from "../images/algo_trial_banner.png";

const StrategyDetails = () => {
  const [isBacktestZoomed, setBacktestZoomed] = useState(false);
  const [isEquityCurveZoomed, setEquityCurveZoomed] = useState(false);
  const [isMonteCarloZoomed, setMonteCarloZoomed] = useState(false);
  const [isReturnsZoomed, setReturnsZoomed] = useState(false);

  const title = "TrendHunter";
  const subtitle =
    "An EMA based intraday strategy on the 5 minute chart with a built-in volatility centric risk model.";
  return (
    <div>
      <div className="">
        <div className="hero-content text-left mb-[120px]">
          <h1
            className="flex-grow pb-[14px] ] mt-[20px] md:mt-4 mb-2 text-center font-bold tracking-tighter
                      bg-gradient-to-tr from-orange-500 to-pink-500
                      text-transparent bg-clip-text pr-2
                      md:mb-6 text-6xl md:text-8xl"
          >
            {title}
            <a />
          </h1>
          <a
            className="font-medium tracking-widest w-[360px] md:w-[800px]
                      text-white
                      text-sm lg:px-0 md:text-md lg:text-lg
                      inline-block"
          >
            <span>{subtitle}</span>
          </a>
          <div
            className="w-full blur-[200px] rounded-full h-72 absolute mt-[200px]
            bg-gradient-to-t from-black to-[deepskyblue]/20 -z-20"
          ></div>
        </div>
        <div className="strategy-image-wrapper">
          <a
            href="https://buy.stripe.com/bIYdUA4c9bjo75KbJe"
            target="_blank"
            rel="noopener noreferrer"
          >
            {/* <Image
              src={algoTrial}
              style={{
                cursor: "pointer",
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "contain",
              }}
            /> */}
          </a>
        </div>
      </div>

      <div className="strategy-details-container">
        <div className="strategy-details-content">
          <div className="strategy-info">
            {/* Strategy price */}
            {/* <h1>$3,995</h1> */}

            <a
              href="https://buy.stripe.com/5kA6s8gYVgDIeyc14I"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
            >
              <button className="ui-btn-strat mx-start mt-8 mb-4" type="submit">
                <span className="w-full">Buy This Algo</span>
              </button>
            </a>
            <a
              href="https://buy.stripe.com/8wM4k0gYV1IOahW9AJ"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
            >
              <button
                className="ui-btn-strat mx-start mb-[20px]"
                style={{ backgroundColor: "green" }}
                type="submit"
              >
                <span className="w-full">Lease for $97/month</span>
              </button>
            </a>

            <h2 className="returns-table-title font-semibold text-lg mt-4 ">
              Monthly Returns Trading MNQ Since 2019, $25,000 Starting Balance
            </h2>
            <div className="strategy-table mb-[20px]">
              {/* {<MonthlyReturnsTable />} */}
              {/* Large image of the strategy in action */}
              <Image
                src={returnsImage}
                zoomed={isReturnsZoomed}
                alt="image_monthly_returns_table"
                onClick={() => setReturnsZoomed(true)}
                onRequestClose={() => setReturnsZoomed(false)}
              />
            </div>
            <p className="strategy-table-footer">
              *Performance based on a simulated $25,000 account
            </p>
            <p className="strategy-table-footer">
              CFTC Rule 4.41 - Hypothetical or simulated performance results
              have certain limitations. Unlike an actual performance record,
              simulated results do not represent actual trading. Also, since the
              trades have not been executed, the results may have under-or-over
              compensated for the impact, if any, of certain market factors,
              such as lack of liquidity. Simulated trading programs in general
              are also subject to the fact that they are designed with the
              benefit of hindsight. No representation is being made that any
              account will or is likely to achieve profit or losses similar to
              those shown.
            </p>
          </div>
        </div>
      </div>

      <div className="strategy-section">
        <div className="strategy-block">
          <h1>How It Works</h1>
          <p>
            TrendHunter is an EMA based intraday strategy on the 5 minute chart.
            It has a built-in volatility centric model that scales into winning
            positions.
          </p>
        </div>
        <div className="strategy-block">
          <h1>Instruments</h1>
          <p>NQ, MNQ Futures on a 5-minute timeframe</p>
        </div>
        <div className="strategy-block">
          <h1>Account Size</h1>
          <p>
            TrendHunter has shown stable performance in historical backtests,
            and we recommend a simulation account size of at least $10,000 to
            test this strategy.
          </p>
        </div>
      </div>

      <div>
        <h1 className="historical-title">Historical Performance</h1>
      </div>
      <div className="historical-container">
        <div className="historical-block">
          <h1>Backtest 2019-2023</h1>
          <Image
            src={backtestImage}
            zoomed={isBacktestZoomed}
            alt="NinjaTrader historical backtest results of a futures trading system named TrendHunter"
            onClick={() => setBacktestZoomed(true)}
            onRequestClose={() => setBacktestZoomed(false)}
          />
        </div>
        <div className="historical-block">
          <h1>Equity Curve</h1>
          <Image
            src={equityCurveImage}
            zoomed={isEquityCurveZoomed}
            alt="NinjaTrader equity curve of a futures trading strategy named TrendHunter"
            onClick={() => setEquityCurveZoomed(true)}
            onRequestClose={() => setEquityCurveZoomed(false)}
          />
        </div>
        <div className="historical-block">
          <h1>Monte Carlo Simulation</h1>
          <Image
            src={monteCarloImage}
            zoomed={isMonteCarloZoomed}
            alt="Monte Carlo simulation results of the TrendHunter trading strategy"
            onClick={() => setMonteCarloZoomed(true)}
            onRequestClose={() => setMonteCarloZoomed(false)}
          />
        </div>
      </div>

      <div>
        <h1 className="historical-title">Performance Highlights</h1>
      </div>
      <div className="highlights-section">
        <div className="highlights-block">
          <Counter endValue={117778} showPrefix={true} />
          <p>Historical Net Profit</p>
        </div>
        <div className="highlights-block">
          <Counter endValue={9143} showPrefix={true} />
          <p>Maximum Drawdown</p>
        </div>
        <div className="highlights-block">
          <h1>49%</h1>
          <p>Win Rate</p>
        </div>
        <div className="highlights-block">
          <Counter endValue={13} showPrefix={false} />
          <p>Net Profit/DD Ratio</p>
        </div>
      </div>

      <div className="disclaimer-section">
        <h1>Disclaimer</h1>
        <p>
          The results shown for this strategy are hypothetical and based on
          simulated returns. They do not represent actual trading results.
          Trading involves risk, and you should only trade with capital you can
          afford to lose. The performance of this strategy in the past does not
          guarantee future results.
        </p>
      </div>
    </div>
  );
};

export default StrategyDetails;
