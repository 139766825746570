import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Strategies from "./pages/Strategies";
import Checkout from "./pages/Checkout";
import ThankYou from "./pages/ThankYou";
import Contact from "./pages/Contact";
import About from "./pages/About";
import Faq from "./pages/Faq";
import StrategyDetails from "./components/StrategyDetails";
import StrategyDetails2 from "./components/StrategyDetails2";
import StrategyDetails3 from "./components/StrategyDetails3";
import StrategyDetails4 from "./components/StrategyDetails4";
import StrategyDetails5 from "./components/StrategyDetails5";
import StrategyDetails6 from "./components/StrategyDetails6";
import StrategyDetails7 from "./components/StrategyDetails7";
import StrategyDetails8 from "./components/StrategyDetails8";

import ScrollToTop from "./components/ScrollToTop";
import TradingPlatform from "./pages/TradingPlatform";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import RiskDisclosure from "./pages/RiskDisclosure";
import ReturnPolicy from "./pages/ReturnPolicy";
import Affiliates from "./pages/Affiliates";
import BlogList from "./components/BlogList";
import BlogPost from "./components/BlogPost"; // Import the BlogPost component
import ZTAQuantPortfolioDetails from "./components/ZTAQuantPortfolioDetails";
import DelayedPopup from "./components/DelayedPopup";
import PortfolioAnalyzer from "./pages/PortfolioAnalyzer";
import QuantPortfolio from "./pages/QuantPortfolio";

function App() {
  useEffect(() => {
    document.title = "Zion Trading Algos";
  }, []);
  return (
    <Router>
      <DelayedPopup delay={30000} />
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/strategies" element={<Strategies />} />
        <Route path="/strategies/TradeOrFade" element={<StrategyDetails />} />
        <Route path="/strategies/TrendHunter" element={<StrategyDetails2 />} />
        <Route path="/strategies/GoldDigger" element={<StrategyDetails3 />} />
        <Route
          path="/strategies/CrudeConqueror"
          element={<StrategyDetails4 />}
        />
        {/* <Route path="/strategies/KCSniper" element={<StrategyDetails5 />} /> */}
        {/* <Route path="/strategies/BreakoutLong" element={<StrategyDetails6 />} />
        <Route
          path="/strategies/ReversionRally"
          element={<StrategyDetails7 />}
        /> */}
        <Route
          path="/strategies/CandleScalper"
          element={<StrategyDetails8 />}
        />
        <Route path="/platform" element={<TradingPlatform />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/thankyou" element={<ThankYou />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/risk" element={<RiskDisclosure />} />
        <Route path="/return" element={<ReturnPolicy />} />
        <Route path="/affiliates" element={<Affiliates />} />
        <Route path="/blog" element={<BlogList />} />
        <Route path="/blog/post/:id/*" element={<BlogPost />} />{" "}
        <Route
          path="/portfolios/zta_quant_1"
          // element={<ZTAQuantPortfolioDetails />}
          element={<QuantPortfolio />}
        />
        <Route path="/portfolio_analyzer" element={<PortfolioAnalyzer />} />
        {/* Use :id instead of :post_title */}
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
