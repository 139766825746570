import React from 'react';
import Counter from "./Counter";

// const performanceData = [
//   { label: "Historical Net Profit", value: "$701604" },
//   { label: "Maximum Drawdown", value: "$10062" },
//   { label: "Win Rate", value: "52%" },
//   { label: "Net Profit/DD Ratio", value: "69" }
// ];
const performanceData = [
  { label: "Historical Net Profit", value: 701604, showPrefix: true, showPostfix: false },
  { label: "Maximum Drawdown", value: 10062, showPrefix: true, showPostfix: false },
  { label: "Win Rate", value: 52, showPrefix: false, showPostfix: true  },
  { label: "Net Profit/DD Ratio", value: 69, showPrefix: false, showPostfix: false}
];

function PerformanceHighlight({label, value, pre, post}) {
    return (
        <div className="flex flex-col lg:mb-0 md:w-[280px] mb-10">
            <div className="self-start md:self-center">
                {/*{value}*/}
                <Counter endValue={value} showPrefix={pre} showPostfix={post} className={"text-2xl md:text-5xl lg:text-6xl font-medium tracking-tighter leading-none"}/>
            </div>
      <div className="self-start md:self-center mt-2 md:mt-5 text-[12px] md:text-2xl tracking-tighter leading-none">{label}</div>
    </div>
  );
}

const PerformanceComponent = () => {
  return (
    <>
      {/* Large screens: Two columns with a vertical divider */}
        <div
            className="hidden md:flex md:mx-auto flex-wrap items-start justify-start mt-16 w-full text-slate-300 max-md:mt-10 max-md:max-w-full">
            {performanceData.map((item, index) => (
                <React.Fragment key={index}>
                    <PerformanceHighlight label={item.label} value={item.value} pre={item.showPrefix} post={item.showPostfix}/>
                    {index < performanceData.length - 1 && (
                        <div
                            className={`h-[100px] border border-stone-500`}/>)
                    }
                </React.Fragment>
            ))}
        </div>

      {/* Small screens: Single column layout */}
        <div className="flex md:hidden justify-evenly items-start mt-8 w-full text-slate-300">
            <div className="flex flex-col w-[140px]">
                {performanceData.slice(0, 2).map((stat, index) => (
                    <PerformanceHighlight key={index} label={stat.label} value={stat.value} pre={stat.showPrefix} post={stat.showPostfix}/>
                ))}
            </div>
            <div className="shrink-0 w-0 border border-solid border-stone-500 h-[140px]"/>
            <div className="flex flex-col">
                {performanceData.slice(2).map((stat, index) => (
                    <PerformanceHighlight key={index} label={stat.label} value={stat.value} pre={stat.showPrefix} post={stat.showPostfix}/>
                ))}
            </div>
        </div>
    </>
  );
};

function PerformanceHighlightsSection() {
    return (
        <section className="flex flex-col items-start px-10 pt-8 md:pt-14 pb-4 md:pb-20 md:mt-12 max-w-full text-center bg-[#202026] rounded-2xl
                        border border-gray-200/20 justify-start gap-2.5
                         max-md:px-5 shadow-2xl z-20">
            <div className="flex flex-col text-center max-w-full rounded-none">
                <h2 className="flex self-center pr-5 pl-5 max-w-full text-2xl md:text-5xl lg:text-6xl font-semibold leading-none w-full md:w-[793px]">
                    <p className="tracking-tighter text-slate-300 pr-3">Performance</p>
                    <p className="pb-2 tracking-wider text-transparent bg-clip-text bg-[linear-gradient(90deg,#569DB7_0%,#C2D1FB_100%)]"> Highlights</p>
                </h2>
                <PerformanceComponent/>
            </div>
        </section>
  );
}

export default PerformanceHighlightsSection;