import React from "react";
import HeroTitle from "../components/HeroTitle";
import "./Strategies.css";
import SectionTitleStrategies from "../components/SectionTitleStrategies";

const Strategies = () => {
  return (
    <div className="strategies-container">
        <div className="color_container">
            <SectionTitleStrategies
        title="Our Strategies"
        subtitle="Choose from any of our thoroughly backtested and robust systems."
          />
        </div>
    </div>
  );
};

export default Strategies;
