"use client";
import imgHistoricMain from "../images/portfolio_analyzer/historic_1.png";
import imgHistoricEquity from "../images/portfolio_analyzer/historic_2.png";
import imgLiveMain from "../images/portfolio_analyzer/live_1.png";
import imgHistoricDrawdown from "../images/portfolio_analyzer/historic_drawdown.png";
import imgLiveCorrelation from "../images/portfolio_analyzer/live_correlation.png";


import { motion, useScroll, useSpring, useTransform } from "framer-motion";
import React, { useRef } from "react";
import clsx from "clsx";
import Banner from "./Banner";
import HeroTitle from "./HeroTitle";
import { Fade } from "react-reveal";
import {Button} from "@material-tailwind/react";

const PortfolioAnalyzerContent = () => {
    const heading = "Unlock the Potential of Algorithmic Trading with ";
    const description = "An Analysis Tool Built for a Portfolio of Zion Trading Algos";
    const images = [
    {
      src: imgHistoricMain,
      alt: "Historical Backtest Metrics",
    },
    {
      src: imgLiveMain,
      alt: "Live Strategy Metrics",
    },
    {
      src: imgLiveCorrelation,
      alt: "Live Correlation Charts",
    },
    {
      src: imgHistoricEquity,
      alt: "Historical Backtest Charts",
    },
  ];

  return (
    <section className="font-light">
      <div className="sticky top-10 bg-inherit">
        <div className="absolute bottom-0 top-20 z-10 px-0 md:px-[5%]">

        </div>
        <div
          className="relative -z-0"
        >
          <div className="hero-content pt-24 md:pt-24 lg:py-20">
              <div
                  className="w-full blur-[120px] rounded-full h-32 absolute bg-purple-900/20 -z-10 top-16"
              />
              <div
                  className="w-full blur-[120px] rounded-full h-72 absolute bg-blue-900/20 -z-20 top-56"
              />
              <span className="w-auto">
                  <h1 className="lg:w-[1200px] md:w-[800px] mb-2 z-20
                  text-center font-semibold tracking-tighter
                  bg-gradient-to-r from-sky-600 via-pink-500 to-indigo-400
                  inline-block text-transparent bg-clip-text
                  text-4xl lg:px-0 md:text-5xl lg:text-6xl line-clamp-2">
                      {heading}
                      <a className="font-extrabold tracking-tight py-2
                      bg-gradient-to-r from-indigo-300 via-cyan-300/60 to-indigo-200
                      inline-block text-transparent bg-clip-text">
                          Portfolio Analyzer
                      </a>
                  </h1>
                  <Fade>
                      <p className="relative -z-10 text-slate-200/90 font-light tracking-widest text-center md:text-md ">{description}</p>
                  </Fade>
                  <div className="flex items-center justify-center mt-4">
                      {/*<Button size="lg" variant='outlined' disabled={true}*/}
                      {/*          className="w-20px mt-2 mb-5 ms-4 rounded-xl bg-black/20 outline-pink-500 outline">*/}
                      <a className="mt-2 md:mt-5 text-4xl md:text-5xl font-extrabold text-slate-600/20 tracking-tighter">Coming Soon</a>
                      {/*</Button>*/}
                  </div>
                  <div className="hero-content flex">
                    {images.slice(0, 1).map((image, index) => (
                    <div key={index} className=" t-[10vh] w-[100%] md:w-[90vw]">
                        <img
                            src={image.src}
                            className="drop-shadow-2xl"
                            alt={image.alt}
                        />
                        <h1 className="flex-grow mt-20 md:mt-4 -mb-4 md:mb-3 text-center font-bold tracking-tighter
                      bg-gradient-to-r from-slate-100 via-sky-200 to-slate-700
                      text-transparent bg-clip-text
                      text-2xl md:mb-6 md:text-2xl lg:text-3xl">
                            Comprehensive Performance Metrics for detailed insights <br/>
                            into each algorithm's performance, from profits to volatility
                        </h1>
                    </div>
            ))}
          </div>
                  <div className="grid sm:grid-cols-1 md:grid-cols-2">
                      <div
                          className="mt-[40%vh] w-full blur-[160px] rounded-full h-72 absolute bg-gradient-to-r from-pink-500/90 to-sky-700/90 -z-20"
                      ></div>
                      {images.slice(1, 3).map((image, index) => (
                          <div
                              key={index}
                              className={clsx("opacity-90 size-fit object-scale-down sm:px-[10%] md:px-0", {
                                  "z-30 py-[7vh]": index === 0,
                                  "z-20": index === 1
                              })}>
                              <img
                                  src={image.src}
                                  alt={image.alt}
                                  className="drop-shadow-2xl"
                              />
                          </div>
                      ))}
                    </div>
              </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PortfolioAnalyzerContent;
