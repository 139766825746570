import React, { useState } from "react";
import HeroTitle from '../components/HeroTitle';
import FAQ from '../components/FAQ';

const Faq = () => {
    const [faqs, setFaqs] = useState([
        {
          question: "How do you come up with your strategies?",
          answer:
            "Our team makes it a weekly goal to brainstorm new ideas and ways to find more edge in the markets. We are constantly thinking of new strategies, ideas, and theories to test. Our backlog contains dozens of ideas and we're always open to new ones!",
          open: true
        },
        {
          question: "What is the minimum amount of capital required to successfully trade any of your systems?",
          answer: "Honestly, the more the better. We suggest a minimum account size of $10,000 in order to trade any of our systems effectively, and to handle drawdowns. We're not here to sell you some dream that you can turn $362 into $50,000 by next week. This is what the fake gurus teach and we despise it. You need to be properly capitalized in order to have a fair chance of making it in the markets!",
          open: false
        },
        {
          question:
            "What platforms do you support?",
          answer: "As of now, all of our algos are designed to work exclusively with the NinjaTrader 8 platform. We have plans to build systems for TradeStation and others soon.",
          open: false
        },
        {
          question:
            "How do I find my NinjaTrader Machine ID?",
          answer: "To get your Machine ID, go to Help -> About from your Control Panel window. Copy the Machine ID value and paste it into the checkout form. We will use that machine ID to set up your license.",
          open: false
        },
        {
          question:
            "Can I try one of your algos before I buy?",
          answer: "Unfortunately, we do not offer free trials at this time. We do have a leasing option available for those that are interested in our systems, but want a lower cost commitment.",
          open: false
        },
        {
          question:
            "What if I lease a system but realize it's not for me?",
          answer: "If you've leased a system and are a monthly subscriber, we will gladly switch your lease over to another system, free of charge. Just send us a message or an email lettings us know you'd like to switch. ",
          open: false
        },
        {
          question:
            "What happens after checkout?",
          answer: "We ask for your NinjaTrader Machine ID during checkout, and will add that to our license database within 24 hours of purchase, but usually less.\n\nWe will send you an email immediately after checkout with download and setup instructions.",
          open: false
        },
        {
            question:
              "What about updates, fixes, changes to the system?",
            answer: "We're constantly optimizing, fixing and improving our systems. An email will go out to existing customers in the case of an update to any of our products.",
            open: false
          },
          {
            question:
              "If I miss a monthly lease payment, what happens?",
            answer: "We will disable any active monthly license in case of a missed payment. We usually will send you an email notifying you to update your card info and make a payment. We get it, things happen. Reach out to us if you're having trouble making payments.",
            open: false
          },
          {
            question:
              "What are my system requirements in order to run your algos?",
            answer: "An updated version of NinjaTrader 8 is recommended. You can run our algos on your personal PC or choose to deploy them to a VPS or Virtual Private Server hosted by Amazon (AWS), Microsoft (Azure), or any other VPS hosting platform you choose.",
            open: false
          },
          {
            question:
              "Are your algos going to make me rich?",
            answer: "Unfortunately, most likely not. Our algos are designed to manage risk and protect the account first and foremost. We purposely only select stable, performant systems from our catalog of candidates. There is no \"holy grail\" in systematic trading. If there was one, it would be to trade a portfolio of robust, uncorrelated strategies across different market instruments.",
            open: false
          },
          {
            question:
              "Can your algos be used with funded trader programs?",
            answer: "Yes, our systems can be used with funded trader programs if they support automated trading. You will need to be mindful of the rules and constraints of your particular prop firm, but our systems work with them just fine. In fact, our founder passed 3 50k evaluations using our algos!",
            open: false
          },
          {
            question:
              "I want to turn my idea into an automated strategy, can you help me?",
            answer: "We do not provide work for hire services at this time due to time constraints, our team is very busy building our backlog of strategies. However, feel free to reach out to us via the contact form, we may be able to work something out.",
            open: false
          }
      ]);

      const toggleFAQ = index => {
        setFaqs(
          faqs.map((faq, i) => {
            if (i === index) {
              faq.open = !faq.open;
            } else {
              faq.open = false;
            }
    
            return faq;
          })
        );
      };

    return (
        <div>
            {/*<HeroTitle title='Frequently Asked Questions' subtitle=''>Frequently Asked Questions</HeroTitle>*/}
            <div
                className="w-full blur-[160px] h-72 absolute mt-[120px]
            bg-gradient-to-b from-blue-500/20 to-cyan-600/30 -z-20"
            ></div>
            <div className="pt-[20px] pb-[60px] text-center">
                <h1 className="md:mt-2 mb-1 pr-2 pb-[20px] font-bold tracking-tight
                      bg-gradient-to-tl from-blue-200 to-slate-300
                      text-transparent bg-clip-text text-7xl lg:text-8xl md:mb-6">
                    Frequently Asked <br/>Questions
                </h1>
            </div>

            <div
                className="w-full blur-[200px] rounded-full h-72 absolute mt-[600px]
            bg-gradient-to-b from-blue-600/10 to-sky-600/10 -z-20"
            ></div>
            <div className="faqs">
                {faqs.map((faq, index) => (
                    <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ}/>
                ))}
            </div>
        </div>
    );
};

export default Faq;
