import videoLiveStrategies from "../images/portfolio_analyzer/live_strategies.mp4";
import ztaLogo from "../images/zta_logo.png";
import React, { useRef } from "react";
import imgHistoricEquity from "../images/portfolio_analyzer/historic_equity_chart.png";
//import {Button} from "@material-tailwind/react";
import { Button } from "@material-ui/core";
import {Link} from "react-router-dom";

const PortfolioAnalyzerCta = () => {
  const sectionRef = useRef(null);

  const content = {
    image: {
      src: ztaLogo,
      alt: "zta_logo_top",
    },
    heading: "Get The ZTA Quant Portfolio",
    description:
      "Contains 7 fully automated algos that trade ES, NQ, GC, CL and NG. Guaranteed Access to Portfolio Analyzer",
  };
  const algos_quant = [
    "EquitySurfer",
    "NasdaqNavigator",
    "GoldDigger",
    "CrudeConqueror",
    "CandleScalper",
    "BlueFlame",
    "ZTAGapGuru",
  ];

  return (
    <section className="md:h-screen px-[10%] -py-12 md:py-14 lg:py-20 bg-inherit">
      <div className="blur-[100px] rounded-full h-32 absolute bg-neutral-500/20 mt-[80vh] -z-20" />
      <div className="justify-center flex mt-10">
        <img
          src={content.image.src}
          alt={content.image.alt}
          className="size-16 mb-5"
        />
      </div>

      <div
        className="grid grid-col-2 text-center font-bold tracking-tighter
            bg-gradient-to-r from-indigo-400 via-blue-200 to-blue-400/70
            bg-clip-text text-transparent"
      >
        <h2 className="px-[5%] md:px-[15%] text-center font-bold tracking-normal text-4xl md:mb-6 md:text-4xl lg:text-5xl">
          {content.heading}
          <p className="px-[5%] md:px-[15%] mt-5 text-center leading-tight md:text-md tracking-widest text-slate-50 font-light text-lg">
            {content.description}
          </p>
        </h2>
        <div className="mx-auto mt-5 grid grid-cols-2 gap-3">
          <button className='ui-btn mx-auto inline-block mt-[10vh] md:mt-0 w-[160px]'>
            <Link to="/portfolios/zta_quant_1">
              <span>Portfolio</span>
            </Link>
          </button>
          <button className='ui-btn mx-auto inline-block mt-[10vh] md:mt-0 w-[160px]'>
            <Link to="/strategies">
              <span>Strategies</span>
            </Link>
          </button>
          <a href="/portfolios/zta_quant_1">
          </a>
        </div>

        <div className="justify-items-center grid grid-cols-3 gap-8 mt-10 mb-20">
          {algos_quant.map((logo, index) => (
              <div
                  key={index}
                  className="w-60
                    text-slate-200 px-2 pb-4
                    md:p-[0.875rem]"
            >
              <p className="max-h-12 md:max-h-10 font-bold tracking-wide text-sm md:text-md">
                {logo}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PortfolioAnalyzerCta;
