import React, { useState, useEffect } from 'react';
import './HeroTitle.css';
import PricingCards from "./PricingCards";
import imgTradeOrFade from "../images/trade_performance/tradeorfade_equity.jpg";
import imgTrendHunter from "../images/trade_performance/trendhunter_equity.jpg";
import imgGoldRush from "../images/trade_performance/GoldDiggerEquity.png";
import imgCrudeAwakening from "../images/trade_performance/CrudeConquerorEquity.png";
import imgCandleScalper from "../images/trade_performance/candlescalper_equity.jpg";
import algoTrial from "../images/algo_trial_banner.png";

import StrategyItem from "../components/StrategyItem";
import { Fade } from "react-reveal";

const SectionTitleStrategies = ({ title, subtitle}) => {

  return (
    <div className="hero-content mx-auto pt-[56px] px-[10vw]
                    bg-gradient-to-b from-[#141721] via-black/60 to-[#141721] w-full mb-[56px]">
        <div className="hero-content text-left mb-[120px]">
            <h1 className="flex-grow pb-[14px] ]mt-20 md:mt-4 mb-2 text-center font-bold tracking-tighter
                      bg-gradient-to-r from-rose-200 via-sky-200 to-slate-200
                      text-transparent bg-clip-text pr-2
                      text-5xl md:mb-6 md:text-7xl lg:text-8xl">
                Our Strategies<a/>
            </h1>
            <a className="font-medium tracking-widest w-[360px] md:w-[800px]
                      text-emerald-300
                      text-sm lg:px-0 md:text-md lg:text-lg
                      inline-block">
                <span>{subtitle}</span>
            </a>
            <div
                className="w-full blur-[160px] rounded-full h-72 absolute mt-[200px]
            bg-gradient-to-b from-blue-400/10 to-teal-500/20 z-10"
            ></div>
        </div>
        <div className="hero-content w-full px-[5vw] z-10">
            <Fade>
                <StrategyItem
                    imageSrc={imgTradeOrFade}
                    title="TradeOrFade"
                    description="TradeOrFade is a momentum based intraday strategy on the 5 minute chart. It determines whether or not there is an opening gap on NQ/MNQ and takes advantage of statistically relevant information regarding gap ups and gap downs. It takes a starter position and pyramids into the position."
                />
                <StrategyItem
                    imageSrc={imgTrendHunter}
                    title="TrendHunter"
                    description="TrendHunter is an EMA based intraday strategy on the 5 minute NQ/MNQ chart. It has a built-in volatility centric model that scales into positions. It performs well on trending days in the market, and is designed to reduce risk and take a smaller position size if the market is not in a trend."
                />
                <StrategyItem
                    imageSrc={imgGoldRush}
                    title="GoldDigger"
                    description="GoldDigger is an intraday strategy on the 20 minute chart. Designed to take an initial amount of Gold futures(GC/MGC) contracts, and scales into each position. It uses a few custom indicators that will also be included with purchase."
                />
                <StrategyItem
                    imageSrc={imgCrudeAwakening}
                    title="CrudeConqueror"
                    description="CrudeConqueror is an intraday Crude Oil (CL/MCL) system that trades on the 40 minute chart. It is a short only strategy and pyramids into trades based on current volatility."
                />
                {/* <StrategyItem
              imageSrc={imgKCSniper}
              title="KCSniper"
              description="KCSniper is a Keltner Channel based system on the 10 minute chart. It is long only and does not pyramid into positions, but has a single entry and exit model. It uses volatility based stop loss and take profit targets to remain adaptable. Trades ES/MES and NQ/MNQ."
            /> */}
                {/* <StrategyItem
              imageSrc={imgBreakoutLong}
              title="BreakoutLong"
              description="BreakoutLong is an intraday breakout system that trades on the 30 minute chart. It uses an EMA and looks for a specific price pattern-based breakout. It has volatility derived stop loss and take profit targets and only has a single entry and exit system. Tested on ES/MES, NQ/MNQ, YM/MYM."
            /> */}
                {/* <StrategyItem
              imageSrc={imgReversionRally}
              title="ReversionRally"
              description="ReversionRally is an intraday mean reversion long system on the 60 minute chart. It seeks to capitalize in oversold conditions. Tested on NQ, MNQ, FDAX, FDXS and others"
            /> */}
                <div
                    className="w-full blur-[160px] rounded-full h-72 absolute mt-[200px]
                bg-gradient-to-b from-blue-400/10 to-teal-500/20 -z-10"
                ></div>
                <StrategyItem
                    imageSrc={imgCandleScalper}
                    title="CandleScalper"
                    description="CandleScalper is an intraday NQ/MNQ 30 minute algo. It waits for pullbacks on strong strends and enters on trend continuation."
                />
            </Fade>
        </div>
    </div>
  );
};

export default SectionTitleStrategies;
